export const services = [
  {
    name: "Home Construction Mortgage",
    description:
      "Experience a seamless path to homeownership with our trusted mortgage lending service.",
  },
  {
    name: "iConstruct",
    description:
      "Software bringing you total transparency in the management of process, procurement, construction and legal.",
  },
  {
    name: "Home Purchase Mortgage",
    description:
      "Perhaps you're looking to buy instead of build. We make that possible as well.",
  },
  {
    name: "Insure It",
    description:
      "Easy setup of the various insurance policies you'll be needing for your new property.",
  },
  {
    name: "By and for Africans",
    description:
      "Dwella is born out of a deep frustration with the failing of the market in providing such a service.",
  },
  {
    name: "Transparency, always",
    description:
      "We disclose all fees, processes, loan modification options ensuring to respond to all inquiries, comply with regulations, provide regular statements, and are subject to third-party oversight.",
  },
];
