export const headerLinks = [
  {
    name: "Mortgage",
    action: () => {
      window.location.replace("#contact");
    },
  },
  {
    name: "iConstruct",
    action: () => {
      window.location.replace("#contact");
    },
  },
  {
    name: "Insure It",
    action: () => {
      window.location.replace("#contact");
    },
  },
  {
    name: "Solutions",
    action: () => {
      window.location.replace("#contact");
    },
  },
  {
    name: "Contact",
    action: () => {
      window.location.replace("#contact");
    },
  },
];
