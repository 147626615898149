import React from "react";
import { Button } from "..";
import heroImage from "../../assets/images/hero-image.jpg";
import "./hero.scss";

const Hero = () => {
  return (
    <div className="hero">
      <div className="left">
        <h1>
          Secure your dream <span>home</span> <br /> with Dwella
        </h1>
        <p>Housing the African diaspora back home.</p>
        <Button
          skin="primary"
          text="Get started"
          onClick={() => window.location.replace("#contact")}
        />
      </div>
      <div className="right">
        <img src={heroImage} alt="interior of a house" />
      </div>
    </div>
  );
};

export default Hero;
