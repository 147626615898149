import React, { useState } from "react";
import { Button } from "..";
import logo from "../../assets/logos/logo-black.svg";
import { headerLinks } from "./links";
import "./header.scss";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const HandleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header className="header">
      <div className="con">
        <img src={logo} alt="dwella logo" className="logo" />
        <nav className={`${showMenu && "open"}`}>
          <ul>
            {headerLinks.map((link, index) => (
              <li key={index}>
                <p
                  className="link"
                  onClick={() => {
                    HandleMenu();
                    link.action();
                  }}
                >
                  {link.name}
                </p>
              </li>
            ))}
          </ul>
        </nav>
        <Button
          skin="primary"
          text="Get started"
          onClick={() => {
            HandleMenu();
            window.location.replace("#contact");
          }}
        />
        <div className={`toggler ${showMenu && "open"}`} onClick={HandleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </header>
  );
};

export default Header;
