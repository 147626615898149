import React from "react";
import houseImg1 from "../../assets/images/house-1.png";
import houseImg2 from "../../assets/images/house-2.png";
import houseImg3 from "../../assets/images/house-3.png";
import houseImg4 from "../../assets/images/house-4.png";
import houseImg5 from "../../assets/images/house-5.png";
import "./calculator.scss";

const Calculator = () => {
  const [value, setValue] = React.useState(40000);
  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  const calculateRefund = () => {
    const r = 0.00416; //Annual interest rate (APRC)/12 (months)
    const n = 240; //Number of payments in total: if you make one mortgage payment every month for 25 years, that’s 25*12 = 300

    const getTop = () => {
      return r * Math.pow(1 + r, n);
    };
    const getBottom = () => {
      return Math.pow(1 + r, n) - 1;
    };
    return value * (getTop() / getBottom());
  };

  //   useEffect(() => {
  //     calculateRefund();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  return (
    <div className="calculator">
      <div className="top">
        <div className="amount">
          <h3>monthly refund</h3>
          <p>${Math.floor(calculateRefund())}</p>
        </div>
        <div className="house">
          {value <= 20000 && <img src={houseImg1} alt="" />}
          {value > 20000 && value <= 40000 && <img src={houseImg2} alt="" />}
          {value > 40000 && value <= 60000 && <img src={houseImg3} alt="" />}
          {value > 60000 && value <= 80000 && <img src={houseImg4} alt="" />}
          {value > 80000 && <img src={houseImg5} alt="" />}
        </div>
        <div className="amount">
          <h3>mortgage</h3>
          <p>${value}</p>
        </div>
      </div>
      <div className="range-con">
        <input
          type="range"
          id=""
          name=""
          min="0"
          max="100000"
          value={value}
          step="10000"
          onChange={(e) => {
            handleChange(e);
            calculateRefund();
          }}
        />
        <div className="edges">
          <p>$10,000</p>
          <p>$100,000</p>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
