import React from "react";
import { ServiceCard } from "..";
import { services } from "./data";
import "./our-services.scss";

const OurServices = () => {
  return (
    <section className="services-section">
      <div className="con">
        <div className="section-title">
          <h2>Our services</h2>
        </div>
        <div className="services">
          {services.map((service, index) => (
            <ServiceCard service={service} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurServices;
