import React, { useState, useEffect } from "react";
import { Button } from "..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faMapPin,
  faPaperPlane,
  faPhone,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import "./contact-us.scss";

const ContactUs = () => {
  const [files, setFiles] = useState<FileList>();
  const [previews, setPreviews] = useState<string[]>([]);

  // rendering previews
  useEffect(() => {
    if (!files) return;
    let tmp = [];
    for (let i = 0; i < files.length; i++) {
      console.log("🚀 ~ file: ContactUs.tsx:21 ~ useEffect ~ files:", files);
      tmp.push(files[i].name);
    }
    const objectUrls = tmp;
    setPreviews(objectUrls);

    // free memory
    for (let i = 0; i < objectUrls.length; i++) {
      return () => {
        URL.revokeObjectURL(objectUrls[i]);
      };
    }
  }, [files]);

  return (
    <section className="contact-us-section" id="contact">
      <div className="con">
        <div className="section-title">
          <h2>Contact Us</h2>
        </div>
        <div className="flex">
          <div className="contact-form">
            <h3>Send Us a Mail</h3>
            <form
              action="https://getform.io/f/dd75caea-1971-455f-84d1-29fb0943ded0"
              method="POST"
              encType="multipart/form-data"
            >
              <div className="flex">
                <div className="field">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Your names"
                    required
                  />
                </div>
                <div className="field">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your email"
                    required
                  />
                </div>
              </div>
              <div className="flex">
                <div className="field">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Your phone number"
                    required
                  />
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="country"
                    id="country"
                    placeholder="Your country"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  required
                />
              </div>
              <div className="field">
                <textarea name="message" id="message" required />
              </div>
              <div className="field attachments">
                <input
                  type="file"
                  name="attachments"
                  id="attachments"
                  placeholder="Your attachments"
                  // required
                  // multiple
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      setFiles(e.target.files);
                    }
                  }}
                />
                <label htmlFor="attachments">
                  <FontAwesomeIcon icon={faPlus} />
                  Add attachments
                </label>
                {previews &&
                  previews.map((name, index) => (
                    <p key={index}>
                      <FontAwesomeIcon icon={faFile} />
                      {name}
                    </p>
                  ))}
              </div>
              <input type="hidden" name="_gotcha" style={{ display: "none" }} />
              <Button skin="primary" text="Send message" type="submit" />
            </form>
          </div>
          <div className="contact-info">
            <h3>Our Info</h3>
            <p>Ahh, going old school? We don't mind.</p>

            <div className="details">
              <div className="sub">
                <div className="icon">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </div>
                <div className="content">
                  <h4>Email Address</h4>
                  <p>contact@getdwella.com</p>
                </div>
              </div>
              <div className="sub">
                <div className="icon">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className="content">
                  <h4>Phone Number</h4>
                  <p>+31 6 11428238</p>
                  <p>+49 176 82914157</p>
                </div>
              </div>
              <div className="sub">
                <div className="icon">
                  <FontAwesomeIcon icon={faMapPin} />
                </div>
                <div className="content">
                  <h4>Address</h4>
                  <p>Eindhoven, the Netherlands</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
