import React from "react";
import "./app.scss";
import {
  Header,
  OurServices,
  JoinUsToday,
  FAQ,
  ContactUs,
  Footer,
  Hero,
  CalculatorSection,
} from "./components";

function App() {
  return (
    <div className="App">
      <Header />
      <div className="page-content">
        <Hero />
        <CalculatorSection />
        <OurServices />
        <JoinUsToday />
        <FAQ />
        <ContactUs />
        <Footer />
      </div>
    </div>
  );
}

export default App;
