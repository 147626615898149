import React from "react";
import icon from "../../assets/icons/check-circle.svg";
import "./service-card.scss";

interface ServiceCardProps {
  service: { name: string; description: string };
}

const ServiceCard = (props: ServiceCardProps) => {
  const { name, description } = props.service;
  return (
    <div className="service-card">
      <div className="icon">
        <img src={icon} alt={"check icon"} />
      </div>
      <h3>{name}</h3>
      <p>{description}</p>
    </div>
  );
};

export default ServiceCard;
