import React from "react";
import {
  footerRoutes,
  technologyMenuOptions,
  companyMenuOptions,
  contactMenuOptions,
} from "./data";
import logo from "../../assets/logos/logo-white.svg";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="con">
        <div className="top">
          <div className="left">
            <img src={logo} alt="dwella logo" className="logo" />
            <p>
              We're committed to making the mortgage process easy and
              stress-free for you.
            </p>
            <div className="icons"></div>
          </div>
          <div className="right">
            <nav>
              <div className="sub">
                <h4>Technology</h4>
                <ul>
                  {technologyMenuOptions.map((link, index) => (
                    <li key={index}>
                      <a href={link.path}>{link.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="sub">
                <h4>Company</h4>
                <ul>
                  {companyMenuOptions.map((link, index) => (
                    <li key={index}>
                      <a href={link.path}>{link.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="sub">
                <h4>Contact us</h4>
                <ul>
                  {contactMenuOptions.map((link, index) => (
                    <li key={index}>
                      <span className="icon">{link.icon}</span>
                      <p>{link.text}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="bottom">
          <p>&copy; 2023 Dwella. All rights reserved</p>
          <nav>
            <ul>
              {footerRoutes.map((route, index) => (
                <li key={index}>
                  <a href={route.url}>{route.name}</a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
