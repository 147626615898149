import React from "react";
import { Button } from "..";
import "./join-us-today.scss";

const JoinUsToday = () => {
  return (
    <section className="join-us-today">
      <div className="con">
        <div className="cta">
          <h1>Join us today</h1>
          <p>We're here to help you achieve your home ownership goals</p>
          <Button
            skin="primary"
            text="Get started"
            onClick={() => {
              window.location.replace("#contact");
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default JoinUsToday;
