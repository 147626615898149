export const FAQQuestions = [
    {
    target: "One",
    question: "What is a mortgage?",
    answer:
      "A mortgage is a type of loan used to purchase or maintain a home, land, or other types of real estate. The borrower agrees to pay the lender over time, typically in a series of regular payments that are divided into principal and interest. The property then serves as collateral to secure the loan.",
  },
    {
    target: "Two",
    question: "Why can't I just go to a bank for this service? Why do you even exist?",
    answer:
      "Generally speaking, banks do not offer mortgages for property outside of their territory of activity: i.e banks in your country of residence wouldn't give you a loan for your property abroad. African banks on the other hand will not grant loans for contruction of property to non-residents of their location. Even when they do, they're bad partners, offering very little in the way of support or digitisation, not understanding the role they play process of property acquisition. Call any one up today and see. \n Additionally, and critical to construction in the owner's absence, our top of the line construction management software, iConstruct, is designed to make the process as transparent and easy as possible.",
  },
  {
    target: "Three",
    question: "What documents will I need to provide for a mortgage application?",
    answer:
      "While these can differ greatly depending on individual cicrumstances where we'll inform you accordingly, you can expect to provide the following: proof of income (pay stubs, tax returns), employment verification, bank statements, identification, and information on any existing debts or assets. "
  },   
    
  {
    target: "Three",
    question: "What are the parts of a mortgage payment?",
    answer:
      "A mortgage payment is typically made up of four components: principal, interest, taxes and insurance. The Principal portion is the amount that pays down your outstanding loan amount. Interest is the cost of borrowing money. The amount of interest you pay is determined by your interest rate and your loan balance.",
  },
   {
    target: "Three",
    question: "Can I finance a rental property with you guys?",
    answer:
      "No.",
  },
    {
    target: "Four",
    question:
      "What options do I have if I am struggling to make my mortgage payments?",
    answer:
      "A transparent mortgage loan servicer should provide clear information on the options available to modify the loan if the borrower is struggling to make payments, including loan modifications, forbearance, and other programs. How can I contact my mortgage loan servicer?",
  },
  {
    target: "Five",
    question: "What happens if I default on my mortgage loan?",
    answer:
      "If a borrower defaults on their mortgage loan, the servicer may initiate foreclosure proceedings, which can result in the borrower losing their home. Atransparent mortgage loan servicer should provide clear information on the steps that will be taken in the event of a default, as well as options to avoid foreclosure.",
  },
  {
    target: "Six",
    question: "How can I make my mortgage payments?",
    answer:
      "The mortgage loan servicer should provide clear information on the various payment options, including online payments, automatic payments, and payment by mail.",
  },
  {
    target: "Seven",
    question: "What is an escrow account and how does it work?",
    answer:
      "An escrow account is a separate account that the mortgage loan servicer manages to pay property taxes, insurance, and other expenses on behalf of the borrower. The borrower pays into the account each month, and the servicer uses the funds to pay the bills when they come due.",
  },
];
