import React from "react";
import { FAQQuestions } from "./data";
import "./faq.scss";

const FAQ = () => {
  return (
    <section className="faq-section">
      <div className="con">
        <div className="section-title">
          <h2>Frequently Asked Questions</h2>
          <p>
            We tried to answer most common questions, if you have any
            additional, please get in touch with our friendly team
          </p>
        </div>
        <div className="accordion" id="accordionExample">
          {FAQQuestions.map((question, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header" id={`heading${question.target}`}>
                <button
                  className={`accordion-button ${
                    question.target === "One" ? "" : "collapsed"
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${question.target}`}
                  aria-expanded={question.target === "One" ? "true" : "false"}
                  aria-controls={`collapse${question.target}`}
                >
                  {question.question}
                </button>
              </h2>
              <div
                id={`collapse${question.target}`}
                className={`accordion-collapse ${
                  question.target === "One" ? "collapse show" : "collapse"
                }`}
                aria-labelledby={`heading${question.target}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">{question.answer}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
