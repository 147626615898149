import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationPin,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

export const footerRoutes = [
  {
    name: `Terms & conditions`,
    url: "/",
  },
  {
    name: "Privacy policy",
    url: "/",
  },
];

export const technologyMenuOptions = [
  {
    name: "Diaspora Mortgage",
    path: "",
  },
  {
    name: "iConstruct",
    path: "",
  },
  {
    name: "Insure It",
    path: "",
  },
];

export const companyMenuOptions = [
  {
    name: "Solutions",
    path: "",
  },
];

export const contactMenuOptions = [
  {
    text: "contact@getdwella.com",
    icon: <FontAwesomeIcon icon={faEnvelope} />,
  },
  {
    text: "+31 6 11428238",
    icon: <FontAwesomeIcon icon={faPhone} />,
  },
  {
    text: "Maaschricksel 56, 5912XV Venlo, ",
    icon: <FontAwesomeIcon icon={faLocationPin} />,
  },
];
