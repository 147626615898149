import React, { ReactNode } from "react";
import "./button.scss";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactNode;
  iconPosition?: "left" | "right";
  text?: string;
  loading?: boolean;
  loadingText?: string;
  skin: "primary" | "light" | "red" | "light-red";
}

const Button = (props: ButtonProps) => {
  return (
    <button
      onClick={props.onClick}
      className={`custom-button ${props.skin}`}
      type={props.type}
    >
      {/* display left icon */}
      {typeof props.icon === "string" &&
        props.iconPosition &&
        props.iconPosition === "left" && (
          <div className="icon">
            <img src={props.icon} alt="" />
          </div>
        )}
      {typeof props.icon != "string" &&
        props.iconPosition &&
        props.iconPosition === "left" && (
          <div className="icon">{props.icon}</div>
        )}

      {/* display text  */}
      {props.text && (
        <>
          {props.loading ? (
            <span>{props.loadingText}</span>
          ) : (
            <span>{props.text}</span>
          )}
        </>
      )}

      {/* display right icon  */}
      {typeof props.icon === "string" &&
        props.icon &&
        props.iconPosition !== "left" && (
          <div className="icon">
            <img src={props.icon} alt="" />
          </div>
        )}
      {typeof props.icon != "string" &&
        props.icon &&
        props.iconPosition !== "left" && (
          <div className="icon">{props.icon}</div>
        )}
    </button>
  );
};

export default Button;
