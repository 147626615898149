import React from "react";
import { Calculator } from "..";

const CalculatorSection = () => {
  return (
    <section className="calculator-section">
      <div className="con">
        <div className="right">
          <h2>mortgage calculator</h2>
          <p>
            Calculation based on variables: annuity mortgage, interest rate:
            5.00%, 20-year fixed-rate period.
          </p>
        </div>
        <div className="left">
          <Calculator />
        </div>
      </div>
    </section>
  );
};

export default CalculatorSection;
